@tailwind base;
@tailwind components;
@tailwind utilities;


.ribbon-badge {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    width: 75px; 
    height: 30px; /* Adjust based on your preference */
    text-align: center;
    /* transform: rotate(-45deg); */
    transform-origin: 0 0;
  }
  
  .ribbon-badge span {
    position: absolute;
    display: block;
    width: 100%;
    padding-top: 2px; /* Adjust based on text size */
    background-color: #38A169; /* Your badge color */
    font-weight: bold;

    
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 10px; /* Adjust based on your preference */
    text-transform: uppercase;
  }


  .ribbon-badge-no span {
    position: absolute;
    display: block;
    width: 100%;
    padding-top: 2px; /* Adjust based on text size */
    background-color: #ff3860; /* Your badge color */
    font-weight: bold;
    
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.1);
    color: white;
    font-size: 10px; /* Adjust based on your preference */
    text-transform: uppercase;
  }


  .ribbon-badge-pending span {
    position: absolute;
    display: block;
    width: 100%;
    padding-top: 2px; /* Adjust based on text size */
    background-color: #ECC94B; /* Your badge color */
    
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.1);
    color: black;
    font-size: 10px; /* Adjust based on your preference */
    text-transform: uppercase;
  }


  .ribbon-badge-no {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    width: 75px; 
    height: 30px; /* Adjust based on your preference */
    text-align: center;
    /* transform: rotate(-45deg); */
    transform-origin: 0 0;
  }


  .ribbon-badge-pending {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    width: 75px; 
    height: 30px; /* Adjust based on your preference */
    text-align: center;
    /* transform: rotate(-45deg); */
    transform-origin: 0 0;
  }


  .driver-ribbon {
    position: absolute;
    right: 0px;
    top: 0%;
    z-index: 1;
    
    /* transform: translateY(-50%) rotate(90deg); */
    transform-origin: center center;
  }
  
  .driver-ribbon span {
    display: block;
    background-color:rgb(255, 145, 0); /* Your preferred background color for drivers */
    color: white;
    padding: 5px 15px; /* Adjust based on the size of the tag */
    font-size: 9px; 
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.1);
  }


  .driver-ribbon-no {
    position: absolute;
    right: 0px;
    top: 0%;
    z-index: 1;
    
    /* transform: translateY(-50%) rotate(90deg); */
    transform-origin: center center;
  }

  .driver-ribbon-no span {
    display: block;
    background-color:#ff3860; /* Your preferred background color for drivers */
    color: white;
    padding: 5px 15px; /* Adjust based on the size of the tag */
    font-size: 9px; 
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.1);
  }

 

ul {
    list-style-type: square; /* Ensures that bullets are shown */
}

ol{
    list-style-type: decimal;
}

.autocomplete {
    position: relative;
    width: 100%;
  }
  
  .autocomplete-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
  }
  
  .autocomplete-options {
    position: absolute;
    width: 100%;
    border: 1px solid #ddd;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: white;
    z-index: 1000;
  }
  
  .autocomplete-option {
    padding: 8px;
    cursor: pointer;
  }
  
  .autocomplete-option:hover {
    background-color: #f2f2f2;
  }

  .kebab-horizontal {
    transform: rotate(90deg);
  }


  
  
  
  
  
  
  




